<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            title: 'Message',
            message: '',
            show: false,
            upload_action: '',
            upload_error: '',
            update_status: '', 
            update_status_error: ''
        };
    },
    methods: {
        ...mapActions('modal', ['closeMessageModal']),
        showModal(message) {
            this.message = message;
            this.show = true;
        },

        closeModal() {
            this.closeMessageModal();
            this.show = false;
        },

        doSometing() {
            this.closeMessageModal();
            this.$emit('CloseBtn', true);
        },
       
    },
    mounted() {
        
    }
}
</script>

<template>
    <b-modal v-model="show" @hide="doSometing">
        <template #modal-title>
            {{ title }}
        </template>
        <template #modal-footer>
            <button type="button" class="btn btn-orange" v-on:click="closeModal">OK</button>
        </template>
        <div>
            <p style="white-space:pre-wrap">{{ message }}</p>
        </div>
        <!-- /.modal-dialog -->
    </b-modal>
    <!-- /.modal -->
</template>