<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            title: 'Confirmation',
            message: '',
            confirmedCallback: null,
            show: false,
        };
    },
    methods: {
        ...mapActions('modal', ['closeConfirmModal']),
        showModal(message, confirmedCallback) {
            this.message = message;
            this.confirmedCallback = confirmedCallback;
            this.show = true;
        },
        closeModal() {
            this.closeConfirmModal();
            this.show = false;
        },
        doSometing() {
            this.closeConfirmModal();
            this.$emit('CloseBtn', true);
        },
    }
}
</script>

<template>
    <b-modal v-model="show" @hide="doSometing">
        <template #modal-title>
            {{ title }}
        </template>
        <template #modal-footer>
            <button type="button" class="btn btn-orange" v-on:click="confirmedCallback">Confirm</button>
        </template>
        <div>
            <p style="white-space:pre-wrap !important; overflow-wrap: break-word;">{{ message }}</p>
        </div>
    </b-modal>
</template>