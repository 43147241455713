<script>
export default {
   

    data() {
        return {
        };
    },

    created() {

    },
    props: {
        id: {
            required: true,
            type: String,
        },
        name : {
            required: true,
            type: String,
        },
        placeholder: {
            type: String,
        },
        label: {
            required: true,
            type: String,
        },
        type: {
            default: "text"
        },
        disabled: {
            default: false
        },
        min: {
            default: ""
        },
        max: {
            default: ""
        },
        error: [],
        value: {}
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e);
        },
        // handleEnter() {
        //     if (this.onEnter) {
        //         this.onEnter();
        //     }
        // }
    },
    computed: {
        inputState() {
            if (this.error) {
                if (this.error.length > 0) {
                    return false;
                }
            }
            return null;
        }
    }
};
</script>

<template>
    <div>
        <label for="input-live" class="mb-0">{{ label }}:</label>
        <b-form-input
          :id="id"
          :type="type"
          :name="name"
          :value="value"
          @input="handleInput"
          :state="inputState"
          :placeholder="placeholder"
          :disabled="disabled"
          :min="min"
          :max="max"
          trim
        >
        </b-form-input>
        <b-form-invalid-feedback id="input-live-feedback" :state="false" v-if="error && error.length > 0">
            {{ error[0] }}
        </b-form-invalid-feedback>
    </div>
</template>
